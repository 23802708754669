<!--
 * @Description: 添加/编辑物料关系
 * @Author: ChenXueLin
 * @Date: 2021-11-02 20:33:56
 * @LastEditTime: 2023-03-22 16:31:21
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    :title="dialogType == 1 ? '新增' : dialogType == 2 ? '编辑' : '详情'"
    :visible.sync="addVisible"
    :close-on-click-modal="false"
    width="950px"
    v-loading="editDialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
    :before-close="closeDialog"
    append-to-body
    v-dialogDrag
  >
    <div class="dialogTitle">
      <div class="line"></div>
      <div class="til">产品检测项基础信息</div>
    </div>
    <el-form
      label-width="110px"
      :model="addForm"
      ref="addForm"
      :rules="addFormRules"
    >
      <el-row>
        <el-col :span="9">
          <el-form-item label="产品名称：" prop="productCode">
            <e6-vr-select
              is-title
              v-model="addForm.productCode"
              placeholder="产品名称"
              title="产品名称"
              clearable
              popperClass="popperClass"
              :data="productList"
              :props="{
                id: 'productCode',
                label: 'productName'
              }"
              :popper-append-to-body="false"
              @change="changeProduct"
              v-if="dialogType == 1"
            ></e6-vr-select>
            <el-input
              placeholder="产品名称"
              title="产品名称"
              disabled
              v-model="addForm.productName"
              v-else
              style="width:220px;"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="产品型号：" prop="productCode">
            <e6-vr-select
              is-title
              v-model="addForm.productCode"
              placeholder="产品型号"
              title="产品型号"
              clearable
              :data="productList"
              @change="changeProduct"
              :props="{
                id: 'productCode',
                label: 'productNo'
              }"
              v-if="dialogType == 1"
            ></e6-vr-select>
            <el-input
              placeholder="产品型号"
              title="产品型号"
              disabled
              v-model="addForm.productNo"
              v-else
              style="width:220px;"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="是否启用：" prop="status">
            <el-switch
              v-model="addForm.status"
              :active-value="activeValue"
              active-color="#46BFEA"
              :inactive-value="inactiveValue"
              :disabled="dialogType == 3"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form label-width="110px" :model="addForm">
      <el-row>
        <el-col :span="8">
          <el-form-item label=" 类型：">
            {{ baseInfo.productType }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品分类：">
            {{ baseInfo.categoryName }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品名称:">
            {{ baseInfo.productName }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品型号：">
            {{ baseInfo.productNo }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态：">
            {{ baseInfo.status }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品ID：">
            {{ baseInfo.productCode }}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <div class="dialogTitle operate-action">
        <div class="line"></div>
        <div class="til">产品检测项关系</div>
        <template v-if="dialogType != 3">
          <i class="e6-icon-add_line1" title="新增" @click="handleAdd"></i>
          <i
            class="e6-icon-delete_line1"
            title="删除"
            @click="deleteColumns"
          ></i>
        </template>
      </div>
      <el-form ref="tableForm" :model="tableForm" :rules="tableFormRules">
        <el-table
          ref="table"
          highlight-current-row
          @selection-change="handleSelectionChange"
          :data="tableForm.tableData"
          height="300"
          :show-overflow-tooltip="true"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column align="center" width="220">
            <template slot="header">
              <span style="color:#F56C6C;">*</span>
              <span style="color:#48494c;">检测项名称</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.inspectionConfigId'"
                :rules="tableFormRules.inspectionConfigId"
              >
                <e6-select
                  is-title
                  v-model="scope.row.inspectionConfigId"
                  placeholder="检测项名称"
                  title="检测项名称"
                  :dataList="configList"
                  :filterable="true"
                  :slotTemplate="'tree'"
                  :multiple="false"
                  :issingleleaf="false"
                  :disabledNoCheck="true"
                  ref="t_select"
                  :disabled="dialogType == 3"
                  @multipleSelect="
                    val => {
                      selectConfig(val, scope.row);
                    }
                  "
                ></e6-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="220">
            <template slot="header">
              <span style="color:#F56C6C;">*</span>
              <span style="color:#48494c;">是否必须检测通过</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.isMust'"
                :rules="tableFormRules.require"
              >
                <e6-vr-select
                  is-title
                  v-model="scope.row.isMust"
                  placeholder="是否必须检测通过"
                  title="是否必须检测通过"
                  :data="requireConfigList"
                  :filterable="true"
                  :disabled="dialogType == 3"
                ></e6-vr-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" show-overflow-tooltip>
            <template slot="header">
              <span style="color:#48494c;">检测项类型</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.inspectionTypeStr'"
              >
                {{ scope.row.inspectionTypeStr }}
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="130" show-overflow-tooltip>
            <template slot="header">
              <span style="color:#48494c;">达标值</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.standardDesc'"
              >
                {{ scope.row.standardDesc }}
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="130" show-overflow-tooltip>
            <template slot="header">
              <span style="color:#48494c;">校验字段</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.verifyField'"
              >
                {{ scope.row.verifyField }}
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="150" show-overflow-tooltip>
            <template slot="header">
              <span style="color:#48494c;">调用地址</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.requestAddress'"
              >
                {{ scope.row.requestAddress }}
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <div class="dialog-footer" slot="footer" v-show="dialogType != 3">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import {
  getProductInspection,
  getInspectionConfig,
  getProductInspectionDetail,
  saveProductInspection
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "addTestItem",
  components: {},
  data() {
    return {
      editDialogLoading: false,
      productList: [], //产品列表
      addForm: {
        productCode: "", //产品id
        status: 1,
        productAccessoriesId: "",
        productNo: "",
        productName: "",
        isMust: ""
      },
      configList: [], //检测项下拉框
      activeValue: 1, //启用id
      inactiveValue: 0, //关闭id
      baseInfo: {},
      addFormRules: {
        productCode: [
          {
            required: true,
            message: "请选择产品",
            trigger: ["blur", "change"]
          }
        ]
      },
      tableForm: {
        tableData: []
      },
      tableFormRules: {
        inspectionConfigId: [
          {
            required: true,
            message: "请选择检测项名称",
            trigger: ["blur", "change"]
          }
        ],
        isMust: [
          {
            required: true,
            message: "请选择检测项名称",
            trigger: ["blur", "change"]
          }
        ]
      },
      requireConfigList: [
        {
          id: 0,
          label: "否"
        },
        {
          id: 1,
          label: "是"
        }
      ],
      selectedData: [] //选中的数据
    };
  },
  //dialogType:1新增2编辑3详情
  props: [
    "addVisible",
    "dialogType",
    "detailInfo",
    "clickId",
    "productInspectionId"
  ],
  mixins: [base],
  computed: {},
  created() {},
  watch: {
    addVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.addForm.clearValidate();
          });
          this.initData();
        }
      }
    }
  },
  methods: {
    async initData() {
      let promiseList = [getProductInspection(), getInspectionConfig()];
      let [productRes, configRes] = await Promise.all(promiseList);
      //产品列表
      this.productList = this.getFreezeResponse(productRes, {
        path: "data"
      });
      //安装位置下拉框
      this.handleTreeData(configRes.data);
      if (this.dialogType == 2 || this.dialogType == 3) {
        this.getDetail();
      }
    },
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        data[i]["label"] = data[i].inspectionName;
        data[i]["id"] = data[i].inspectionConfigId;
        data[i]["pid"] = data[i].parentId;
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.configList = data;
    },
    //获取详情
    async getDetail() {
      try {
        let res = await getProductInspectionDetail({ id: this.clickId });
        if (res.code == "OK") {
          this.addForm.productName = res.data.productName;
          this.addForm.productNo = res.data.productNo;
          this.addForm.productCode = res.data.productCode;
          this.addForm.productAccessoriesId = res.data.productAccessoriesId;
          this.baseInfo = { ...res.data };
          delete this.baseInfo.inspectionDetList;
          this.tableForm.tableData = res.data.inspectionDetList;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    changeProduct(val, node) {
      this.baseInfo = { ...node };
    },
    //点击添加
    handleAdd() {
      let tableData = this.tableForm.tableData;
      tableData.push({
        inspectionConfigId: "",
        inspectionName: "",
        inspectionTypeStr: "",
        standardDesc: "",
        verifyField: "",
        requestAddress: "",
        isMust: "",
        amount: 1
      });
      this.tableForm.tableData = tableData;
    },
    //点击删除多行
    deleteColumns() {
      let tableData = this.tableForm.tableData;
      tableData = tableData.filter(item => {
        return !this.selectedData.some(
          i => i.inspectionConfigId == item.inspectionConfigId
        );
      });
      this.$nextTick(() => {
        this.tableForm.tableData = [...tableData];
      });
    },
    //点击确定添加
    handleSubmit() {
      const p1 = new Promise(resolve => {
        this.$refs["addForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      const p2 = new Promise(resolve => {
        this.$refs["tableForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      Promise.all([p1, p2]).then(() => {
        this.saveProductInspectionReq();
      });
    },
    //选择辅料名称
    selectConfig(value, row) {
      let obj = value.length ? value[0] : {};
      row.inspectionTypeStr = obj.inspectionTypeStr
        ? obj.inspectionTypeStr
        : "";
      row.standardDesc = obj.standardDesc ? obj.standardDesc : "";
      row.verifyField = obj.verifyField ? obj.verifyField : "";
      row.requestAddress = obj.requestAddress ? obj.requestAddress : "";
    },
    //多选勾中的数据
    handleSelectionChange(columns) {
      this.selectedData = columns;
    },
    //添加/编辑请求
    async saveProductInspectionReq() {
      try {
        this.editDialogLoading = true;
        let res = await saveProductInspection({
          ...this.addForm,
          productInspectionId: this.productInspectionId,
          inspectionList: this.tableForm.tableData
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.addForm = {
        productCode: "", //产品id
        status: 1,
        productAccessoriesId: ""
      };
      this.baseInfo = {};
      this.$refs.addForm.resetFields();
      this.tableForm.tableData = [];
      this.$emit("update:addVisible", false);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/utils.scss";
@import "@/assets/styles/variables.scss";
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;

    .el-form {
      .el-table {
        .el-form-item {
          margin-bottom: 12px;
          .base-select {
            width: 180px;
            .el-input {
              width: 180px;
            }
          }
          .el-input {
            width: 140px;
          }
        }
        .el-tooltip {
          .el-form-item__content {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .dialogTitle {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      width: 100%;
      justify-content: flex-start;
      .line {
        width: 4px;
        height: 25px;
        background: #46bfea;
        margin-right: 5px;
      }
      .til {
        font-size: 16px;
        font-weight: 500;
        margin-right: 15px;
      }
      i {
        margin-right: 10px;
        font-size: 20px;
        color: #46bfea;
      }
    }
  }
}
</style>
